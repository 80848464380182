import type {EnumDictionary} from "~/app.vue";

export function sanitizeLanguageFileJSON(messages:EnumDictionary<string, string>):EnumDictionary<string, string> {
    const withoutNulls = {} as EnumDictionary<string, null | string>;
    for (const key in messages) {
        if (messages[key] !== null) {
            withoutNulls[key] = messages[key].replace("{{", "[").replace("}}", "]").replace(/^\{(.*)}$/, "untranslated: $1");
        } else {
            withoutNulls[key] = key;
        }
    }
    return withoutNulls as EnumDictionary<string, string>;
}
